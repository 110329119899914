import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Eko from "/src/components/img/eko"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

import Ekoo2 from "/src/components/img/ekoo/2"
import Ekoo3 from "/src/components/img/ekoo/3"

export default ({data}) => (
  <Layout>
    <SEO title="Životní a populační strategie druhů" />
    <h1>Životní a populační strategie druhů</h1>

    <p><strong>Životn&iacute; strategie</strong> (tj. <strong>populačn&iacute; strategie</strong>) je souborn&eacute; označen&iacute; vlastnost&iacute; organismů pro osidlov&aacute;n&iacute; nov&yacute;ch stanovi&scaron;ť a pro&nbsp;přež&iacute;v&aacute;n&iacute; na různ&yacute;ch typech stanovi&scaron;ť. Jde o selekčně podm&iacute;něn&yacute; vklad energie do určit&yacute;ch vlastnost&iacute; či procesů, kter&eacute; umožňuj&iacute; přežit&iacute; druhu na dan&eacute;m stanovi&scaron;ti.</p>
    <hr />
    <h2 id="r-K-strategie">Životn&iacute; strategie r/K</h2>
    <p>Dělen&iacute; na r-strat&eacute;gy a k-strat&eacute;gy popisuje 2 př&iacute;stupy k&nbsp;životn&iacute; strategii, založen&eacute; předev&scaron;&iacute;m na rychlosti růstu populace a jej&iacute; schopnosti přež&iacute;v&aacute;n&iacute;.</p>
    <Ekoo2/>
    <p><strong>Oportunist&eacute;</strong><strong>(r-strat&eacute;gov&eacute;) </strong>se vyznačuj&iacute; vysokou kvantitou potomků a jejich populace tak rychle roste. Na druhou stranu jsou kr&aacute;tkověc&iacute;. Maj&iacute; &scaron;irokou ekologickou amplitudu a roz&scaron;&iacute;ř&iacute; se tak rychle na mnoha různ&yacute;ch, ide&aacute;lně neobsazen&yacute;ch stanovi&scaron;t&iacute;ch. Obvykle nastupuj&iacute; při disturbanci ekosyst&eacute;mu (např. pož&aacute;r, častěji lidsk&aacute; činnost) a s&nbsp;postupuj&iacute;c&iacute; sukces&iacute; jsou vytlačeni k-strat&eacute;gy. Neobstoj&iacute; totiž v&nbsp;jejich konkurenci. Př&iacute;padně mohou trvaleji okupovat stanovi&scaron;tě s&nbsp;blokovanou sukces&iacute;, např&iacute;klad suťovi&scaron;tě či sk&aacute;ly.</p>
    <p>Jde zpravidla o pion&yacute;rsk&eacute; rostliny (bř&iacute;za) a drobn&aacute; zv&iacute;řata (typicky krysa).</p>
    <p><strong>Konzervativci (k-strat&eacute;gov&eacute;) </strong>se naopak vyznačuji men&scaron;&iacute; kvantitou potomků a jejich vy&scaron;&scaron;&iacute; kvalitou. Schopnost přežit&iacute; je vy&scaron;&scaron;&iacute;, jsou konkurenceschopněj&scaron;&iacute; a dož&iacute;vaj&iacute; se vy&scaron;&scaron;&iacute;ho věku. Během sukcese postupně vytlač&iacute; r-strat&eacute;gy a tvoř&iacute; pak klimax. V&nbsp;př&iacute;padě v&yacute;razněj&scaron;&iacute; disturbance ekosyst&eacute;mu a jeho n&aacute;sledn&eacute;ho zhroucen&iacute; v&scaron;ak nastupuj&iacute; opět r-strat&eacute;gov&eacute;, kteř&iacute; dan&eacute;ho stavu využij&iacute;.</p>
    <p>Ačkoliv by v&nbsp;krajině měli převažovat k-strat&eacute;gov&eacute;, d&iacute;ky četn&yacute;m disturbanc&iacute;m stanovi&scaron;ť člověkem je tomu naopak. Obzvl&aacute;&scaron;tě ekotony, např&iacute;klad okraje lesů, d&iacute;ky čast&eacute;mu naru&scaron;ov&aacute;n&iacute; prostřed&iacute; (nitrifikace a ruderalizace) r-strat&eacute;gům př&iacute;mo &bdquo;nab&iacute;z&iacute;me&ldquo;.</p>
    <hr />
    <h2>Životn&iacute; strategie R/S/C</h2>
    <p>Grimes v&nbsp;roce 1979 navrhl složitěj&scaron;&iacute; klasifikaci populačn&iacute;ch strategi&iacute;, kter&aacute; je použ&iacute;v&aacute;na předev&scaron;&iacute;m u rostlin. Ta vych&aacute;z&iacute; z&nbsp;předpokladu, že organismy jsou různě konkurenceschopn&eacute;, n&aacute;chyln&eacute; na naru&scaron;ov&aacute;n&iacute; a stres, j&iacute;mž se mysl&iacute; předev&scaron;&iacute;m nedostatek či nadbytek zdrojů.</p>
    <Ekoo3/>
    <p><strong>Ruder&aacute;ln&iacute; strat&eacute;gov&eacute; (R-strat&eacute;gov&eacute;)</strong> jsou odoln&iacute; vůči naru&scaron;ov&aacute;n&iacute; biomasy, ale nesn&aacute;&scaron;ej&iacute; stres. Maj&iacute; velkou produkčn&iacute; schopnost, protože potřebuj&iacute; rychle obsadit volnou plochu. Rovněž maj&iacute; rychl&yacute; růst a relativně kr&aacute;tk&yacute; věk. Vyskytuj&iacute; se na stanovi&scaron;t&iacute;ch s&nbsp;dostatkem zdrojů, kter&aacute; jsou vystavena siln&eacute;mu naru&scaron;ov&aacute;n&iacute; &ndash; typicky jde o ruder&aacute;ln&iacute; plochy, ale i &scaron;těrkov&eacute; lavice či lavinov&eacute; svahy.</p>
    <p>Mezi typick&eacute; z&aacute;stupce R-strat&eacute;gů patř&iacute; např&iacute;klad merl&iacute;k, lebeda či žabinec obecn&yacute;.</p>
    <p><strong>Stres sn&aacute;&scaron;ej&iacute;c&iacute; strat&eacute;gov&eacute; (S-strat&eacute;gov&eacute;)</strong> již podle jm&eacute;na sn&aacute;&scaron;&iacute; stres, nesnesou v&scaron;ak naru&scaron;ov&aacute;n&iacute; biomasy. Maj&iacute; n&iacute;zkou reprodukci, pomal&yacute; růst a dlouh&yacute; věk. Na nedostatek zdrojů jsou specificky adaptov&aacute;ni. Typick&aacute; stanovi&scaron;tě v&yacute;skytu S-strat&eacute;gů jsou např&iacute;klad ra&scaron;elini&scaron;tě, slaniska, pou&scaron;tě a sk&aacute;ly. Na &scaron;těrkov&yacute;ch ložisk&aacute;ch je v&scaron;ak nenalezneme, protože zde doch&aacute;z&iacute; k&nbsp;čast&eacute;mu naru&scaron;ov&aacute;n&iacute; biomasy.</p>
    <p>Z&aacute;stupci S-strat&eacute;gů jsou např&iacute;klad borůvky, vřes či borovice.</p>
    <p><strong>Konkurenčn&iacute; strat&eacute;gov&eacute; (C-strat&eacute;gov&eacute;) </strong>maj&iacute; vysokou konkurenceschopnost, kterou v&scaron;ak uplatňuj&iacute; na stanovi&scaron;t&iacute;ch bez stresu a naru&scaron;ov&aacute;n&iacute;. Maj&iacute; středn&iacute; reprodukci, středn&iacute; růst a jsou dlouhověc&iacute;. Nal&eacute;zaj&iacute; se předev&scaron;&iacute;m na klimaxov&yacute;ch stanovi&scaron;t&iacute;ch.</p>
    <p>Mezi v&yacute;znamn&eacute; C-strat&eacute;gy patř&iacute; dub, buk lesn&iacute;, jasan či p&yacute;r plaziv&yacute;.</p>
    <hr />
    <h2>Omezen&iacute; a v&yacute;znam populačn&iacute;ch strategi&iacute;</h2>
    <p>V&scaron;echny strategie nejsou s&nbsp;ohledem na uveden&eacute; kategorie jednoznačně vyhraněn&eacute; a lze rozli&scaron;it jejich různ&eacute; kombinace. Např&iacute;klad ak&aacute;t kombinuje vlastnosti R, S&nbsp;i C strat&eacute;gů a jde tak o C-S-R strat&eacute;ga.</p>
    <p>Jednodu&scaron;&scaron;&iacute; vymezen&iacute; na r- a k-strat&eacute;gy je pak třeba ch&aacute;pat jako kontinuum s&nbsp;vymezen&yacute;mi krajn&iacute;mi extr&eacute;my, nejde o dvě separ&aacute;tn&iacute; skupiny.</p>
    <p>Pozn&aacute;n&iacute; populačn&iacute;ch (životn&iacute;ch) strategi&iacute; m&aacute; svůj v&yacute;znam předev&scaron;&iacute;m při huben&iacute; &scaron;kůdců a než&aacute;douc&iacute;ch druhů, ale rovněž při ochraně ohrožen&yacute;ch druhů. Typick&eacute; S-strat&eacute;gy lze např&iacute;klad dobře likvidovat naru&scaron;ov&aacute;n&iacute;m biomasy. Naopak ani plo&scaron;n&aacute; likvidace populace r-strat&eacute;ga nevede k&nbsp;jej&iacute;mu &uacute;pln&eacute;mu vym&yacute;cen&iacute;, přestože při n&iacute; hyne obrovsk&eacute; množstv&iacute; jedinců.</p>

    <hr />
    <ImgCon><Eko /><div>
    <h2>Studijn&iacute; materi&aacute;ly Ekologie a ŽP</h2>
    <p>T&eacute;ma <strong>Životní a populační strategie druhů</strong> je souč&aacute;st&iacute; <Link to="/ekologie-zivotni-prostredi/">studijn&iacute;ch materi&aacute;lů Ekologie a životní prostředí</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte n&aacute;sleduj&iacute;c&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/ekologie-zivotni-prostredi/"><button>Rozcestník ekologie a ŽP</button></Link>
    <Link to="/ekologie-zivotni-prostredi/zivotni-vzrustove-formy/"><button className="inv">&larr; Životní a vzrůstobé formy rostlin</button></Link>
    <Link to="/ekologie-zivotni-prostredi/chemicky-boj-alelopatie/"><button className="inv">Chemický boj druhů (alelopatie) &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
